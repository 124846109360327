import ens from "../images/ens.png";

function TokenMedia({nft}) {
   if ((nft.mediaUrl == null || nft.mediaUrl.length === 0) && !nft.isEnsDomain) {
      return <div className="flex aspect-square text-gray-200 justify-center items-center text-9xl">?</div>;
   } else {
      return (nft.mediaType === "video" ?                           
         <video playsInline autoPlay muted loop preload="metadata">
            <source src={nft.mediaUrl} type="video/mp4" />
         </video>
         :   
         <img className="w-full aspect-square object-contain object-top" loading="lazy" 
            src={nft.isEnsDomain ? ens : "https://ik.imagekit.io/gyxw9y8p24n/tr:w-500/" + nft.mediaUrl} alt={nft.title} />
      );
   }
}

export default TokenMedia;
