import { useEffect, useState } from "react";
import Web3 from "web3";
import TokenMedia from "./TokenMedia";
import { formatNumber } from "./utilities/MathUtils";
import ens from "../images/ens.png";
import link_external_white from "../images/link_external_white.png";

function NftGrid({nfts, floorPrices, financials}) {            
   return (               
      nfts.length === 0 ?
      <div className="mx-auto p-4 text-center bg-gray-100 dark:bg-zinc-900/50 dark:text-neutral-50">No NFTs for this address! Time to buy one?</div>
      :
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      {
         nfts.map((nft, i) => {
            return <div key={i} className="flow flow-col border-4 border-gray-700 dark:border-slate-900">
               <div className="grid grid-cols-2">
                  <div className="text-xs text-center">                  
                     <div className="relative">
                        {nft.links?.openseaToken != null ?
                           <a className="no-underline" href={nft.links.openseaToken} target="_blank" rel="noreferrer">
                              <TokenMedia nft={nft} />
                           </a>
                           :
                           <TokenMedia nft={nft} />
                        }
                        {nft.links?.openseaCollection && nft.collection?.imageUrl != null && !nft.isEnsDomain &&
                           <a href={nft.links.openseaCollection} target="_blank" rel="noreferrer">
                              <img loading="lazy" src={nft.collection.imageUrl} className="w-1/4 drop-shadow-md absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full border border-gray-800" alt="External link" />                           
                           </a>                        
                        }
                        {nft.links?.twitter &&
                           <span className="absolute left-1 -bottom-6 text-xs dark:text-neutral-50">[<a href={nft.links.twitter} target="_blank" rel="noreferrer">Twitter</a>]</span>
                        }                     
                        {nft.links?.discord &&
                           <span className="absolute right-1 -bottom-6 text-xs dark:text-neutral-50">[<a href={nft.links.discord} target="_blank" rel="noreferrer">Discord</a>]</span>
                        }                     
                     </div>
                     {/* Spacer */}
                     <div className="w-1/6 aspect-square invisible"></div>                     
                     <div className="-mt-4 p-2"></div>
                  </div>
                  <div>
                     <div className="text-base xl:text-xs p-2">
                        {nft.stats != null &&                     
                           <div className="grid grid-cols-2 gap-2">
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Tot Spent: </p>
                                 <p className="dark:text-neutral-50">
                                    {nft.collection != null && financials.nfts[`${nft.collection.slug}-${nft.tokenId}`] != null ?
                                       <span className="dark:text-neutral-50">Ξ{financials.nfts[`${nft.collection.slug}-${nft.tokenId}`].spent.toFixed(2)}</span>
                                       : <span className="dark:text-neutral-50">Loading...</span>
                                    }
                                 </p>
                              </div>
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Floor: </p>
                                 <p className="dark:text-neutral-50">
                                    {nft.collection != null && floorPrices.items[nft.collection.slug] != null ?
                                       <span className={
                                          financials.nfts[`${nft.collection.slug}-${nft.tokenId}`] != null ?
                                             (floorPrices.items[nft.collection.slug] >= (financials.nfts[`${nft.collection.slug}-${nft.tokenId}`].spent / nft.tokenCount) ? 
                                                "text-green-700" : "text-red-700"
                                             ) : "dark:text-neutral-50"
                                       }>Ξ{floorPrices.items[nft.collection.slug].toFixed(2)} <small>{nft.tokenCount > 1 ? `(${nft.tokenCount})` : ""}</small></span>
                                       : <span className="dark:text-neutral-50">Loading...</span>
                                    }
                                 </p>
                              </div>

                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">1D Vol.:</p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.one_day_volume.toFixed(2)}
                                 </p>
                              </div>
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Avg Price: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.one_day_average_price.toFixed(2)}
                                 </p>
                              </div>

                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">7D Vol.: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.seven_day_volume.toFixed(2)}
                                 </p>
                              </div>
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Avg Price: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.seven_day_average_price.toFixed(2)}
                                 </p>
                              </div>

                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">30D Vol.: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.thirty_day_volume.toFixed(2)}
                                 </p>
                              </div>
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Avg Price: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.thirty_day_average_price.toFixed(2)}
                                 </p>
                              </div>

                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">All Vol.: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.total_volume.toFixed(2)}
                                 </p>
                              </div>
                              <div className="">
                                 <p className="text-xs text-fuchsia-600 font-medium">Avg Price: </p>
                                 <p className="dark:text-neutral-50">
                                    Ξ{nft.stats.thirty_day_average_price.toFixed(2)}
                                 </p>
                              </div>                           
                           </div>
                        }
                     </div>
                  </div>
               </div>
               <div className="text-base xl:text-xs">
                  <div className="bg-gray-100 p-2 dark:bg-black/25">
                     <div className="text-ellipsis overflow-hidden font-medium dark:text-neutral-50">
                        <span className="dark:text-neutral-50">{
                           nft.isEnsDomain ? 
                           "ENS domain" : 
                           nft.collection != null ? nft.collection.name : nft.title
                        }</span>{" "}
                        [<a href={"https://etherscan.io/address/" + nft.contractAddress + "#code"} target="_blank" rel="noreferrer">Etherscan</a>]
                        {!nft.isEnsDomain &&
                           <div className="dark:text-neutral-50">
                              Token #{nft.tokenId}{/*Web3.utils.hexToNumberString(nft.tokenId)*/}{" "}
                              {nft.tokenCount > 1 && nft.tokenType === "ERC1155" &&
                                 <span className="dark:text-neutral-50">(Qty: {nft.tokenCount})</span>
                              }
                           </div>
                        }
                     </div>
                     {nft.description != null &&
                        <div className="line-clamp-3">
                           <span className="dark:text-neutral-50">{nft.description.substr(0, 250) + "\u2026"}</span>
                        </div>
                     }
                  </div>
                  <div className="p-2">                            
                     <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                        {nft.attributes != null && nft.attributes.map((attr, j) => {
                           return <div key={j} className="truncate">
                              <p className="text-xs text-fuchsia-600 font-medium">{attr.trait_type}: </p>
                              <p className="dark:text-neutral-50">{attr.value}</p>
                           </div>
                        })}
                     </div>
                  </div>
               </div>
            </div>
         })
      }
      </div>
   );
}

export default NftGrid;
