import { useEffect, useState, useContext } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { useWeb3Context } from "./hooks/Web3Context";
import MessageModal from "./utilities/MessageModal";
import { DuplicateIcon } from "@heroicons/react/outline";

function Web3Login({ infuraId, chainId, chainName, onLoggedIn, onLoggedOut }) {
   const {
      web3Modal, setWeb3Modal,
      web3, setWeb3,
      web3Provider, setWeb3Provider,
      isConnected, setIsConnected,
      connectedAccount, setConnectedAccount
   } = useWeb3Context();
   const [isWrongNetworkDialogOpen, setIsWrongNetworkDialogOpen] = useState(false);

   const initWeb3Modal = () => {
      // fortmatic: {
      //    package: Fortmatic,
      //    options: {
      //       key: "..."
      //    }
      // },
      const web3Modal = new Web3Modal({
         cacheProvider: true,
         disableInjectedProvider: false,
         providerOptions: {
            walletconnect: {
               package: WalletConnectProvider,
               options: {
                  infuraId: infuraId,
                  qrcodeModalOptions: {
                     mobileLinks: ["rainbow", "metamask", "argent", "trust", "imtoken", "pillar"]
                  },
               }
            }
         }
      });

      setWeb3Modal(web3Modal);
   }

   const handleLogin = async () => {
      try {
         let provider = await web3Modal.connect();
         let web3 = new Web3(provider);

         setIsConnected(true);
         setWeb3Provider(web3Provider);
         setWeb3(web3);

         let currChainId = await web3.eth.getChainId();
         if (currChainId.toString() !== chainId.toString()) {
            //alert(`You're on the wrong network. Please switch to ${chainName} and try again.`);
            setIsWrongNetworkDialogOpen(true);
            return;
         }

         const accounts = await web3.eth.getAccounts();
         if (accounts.length === 0)
            return;

         let address = Web3.utils.toChecksumAddress(accounts[0]);
         setConnectedAccount(address);

         if (onLoggedIn)
            onLoggedIn(address);

         provider.on("error", e => console.error("WS Error", e));
         provider.on("end", e => console.error("WS End", e));
         provider.on("disconnect", error => {
            console.log(`Disconnect: ${error}`);
         });
         provider.on("connect", info => {
            console.log(`Connect: ${info}`);
         });
         provider.on("accountsChanged", accounts => {
            console.log(`Accounts changed: ${accounts}`);
         });
         provider.on("chainChanged", chainId => {
            console.log(`Chain changed: ${chainId}`);
         });
         // provider.on("networkChanged", networkId => {
         //    console.log(`Network changed: ${networkId}`);
         // });
      } catch (err) {
         console.log(`ERR: ${err.message}`);
      }
   }

   const handleLogout = async () => {
      try {
         if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
         }
         await web3Modal.clearCachedProvider();
         localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");

         let address = connectedAccount;

         setWeb3(null);
         setWeb3Provider(null);
         setIsConnected(false);
         setConnectedAccount(null);

         if (onLoggedOut)
            onLoggedOut(address);
      } catch (err) {
         console.log(`ERR: ${err.message}`);
      }
   }

   const handleCopyAddress = async () => {
      await navigator.clipboard.writeText(connectedAccount);
   }

   const onCloseWrongNetworkDialog = () => {
      setIsWrongNetworkDialogOpen(false);
   }

   useEffect(() => {
      initWeb3Modal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (web3Modal && web3Modal.cachedProvider) {
         handleLogin();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [web3Modal])

   return (
      isConnected && connectedAccount != null ?
         <div className="text-sm text-right">
            <div className="text-cyan-300">
               {String(connectedAccount).substring(0, 6) + "..." + String(connectedAccount).substring(38)}
               <button onClick={handleCopyAddress}><DuplicateIcon className="h-5 w-5 stroke-cyan-300 inline-flex items-right" /></button>
            </div>
            <button className="text-cyan-300 underline" onClick={handleLogout}>Logout</button>
         </div>
         :
         <div>
            <button className="p-2 bg-gray-200 hover:bg-gray-300 dark:text-white dark:bg-violet-900 dark:hover:bg-violet-500/75 rounded-md h-10" onClick={handleLogin}>Login</button>
            <MessageModal isOpen={isWrongNetworkDialogOpen} title="Wrong Network" message={`Please switch to ${chainName} and try again.`} onClose={onCloseWrongNetworkDialog} />
         </div>

   );
}

export default Web3Login;
