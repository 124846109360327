import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Web3Context, useWeb3Context } from "./components/hooks/Web3Context";
import Home from "./pages/Home";
import RegisterPage from "./pages/tools/RegisterPage";
import config from "./configs/config.json";
import config_test from "./configs/config_test.json";
import config_ninjadevhs_test from "./configs/ninjadevhs_test.json";

function App() {
   const [web3Modal, setWeb3Modal] = useState();
   const [web3, setWeb3] = useState();
   const [web3Provider, setWeb3Provider] = useState();
   const [isConnected, setIsConnected] = useState(false);
   const [connectedAccount, setConnectedAccount] = useState(null);

   return (
      <Web3Context.Provider value={{web3Modal, setWeb3Modal, web3, setWeb3, web3Provider, setWeb3Provider, isConnected, setIsConnected, connectedAccount, setConnectedAccount}}>
         <React.StrictMode>
            <BrowserRouter>
               <Routes>
                  <Route path="/" element={
                     <Home config={config} />
                  } />

                  <Route path="/test" element={
                     <Home config={config_test} />
                  } />

                  <Route path="/tools/test/register" element={
                     <RegisterPage config={config_ninjadevhs_test} />
                  } />
               </Routes>
            </BrowserRouter> 
         </React.StrictMode>
      </Web3Context.Provider>
   );
}

export default App;
