import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Web3 from "web3";
import { useWeb3Context } from "../../components/hooks/Web3Context";
import Web3Login from "../../components/Web3Login";
import Register from "../../components/tools/Register";
import Footer from "../../components/Footer";

function RegisterPage({config}) {
   const navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams(); 

   return (
      <div className="h-screen dark:bg-zinc-900">
         <div className="bg-indigo-800 dark:bg-zinc-900 header-pattern">
            <div className="max-w-screen-lg mx-auto px-4 py-12">
               <div className="flex flex-col-reverse md:flex-row gap-16">
                  <div className="flex-2 flex flex-col gap-8">
                     <h1 className="text-4xl font-medium text-cyan-300">Whitelist Registration Demos</h1> 
                  </div>                  
                  <div className="flex-1 grid">
                     <div className="p-2 text-right">
                        
                     </div>
                  </div>
               </div>               
            </div>            
         </div>           
    
         <div className="bg-white dark:bg-zinc-800">         
            <div className="max-w-screen-sm mx-auto px-4 py-8 flex flex-col gap-8 min-h-[50%]">
               <div className="text-lg font-bold">Demo #1 - Easy</div>
               <Register config={config} />

               <div className="text-lg font-bold">Demo #2 - Hard</div>
               <Register config={{...config, projectId: "ninjadevhs2"}} />
            </div>            
         </div>

         <Footer />
      </div>
   );
}

export default RegisterPage;
