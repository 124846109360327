import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ArrowCircleRightIcon, ArrowRightIcon } from "@heroicons/react/outline";
import * as dayjs from 'dayjs'
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { useWeb3Context } from "../../components/hooks/Web3Context";
import { formatNumber } from "../../components/utilities/MathUtils";
import Footer from "../../components/Footer";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, signOut, TwitterAuthProvider } from "firebase/auth";

function Register({config}) {
   const [searchParams, setSearchParams] = useSearchParams(); 
   const [resources, setResources] = useState();
   const [account, setAccount] = useState({address:null, imageUrl:null});
   const [isInitializing, setIsInitializing] = useState(false);
   const [registerRules, setRegisterRules] = useState(null);
   const [registerRuleValidations, setRegisterRuleValidations] = useState({});
   const [message, setMessage] = useState({text: "", type: "info"});
   const [twitterAccount, setTwitterAccount] = useState(null);
   const [isValid, setIsValid] = useState(false);
   
   const {
      web3Modal, setWeb3Modal,
      web3, setWeb3,
      web3Provider, setWeb3Provider,
      isConnected, setIsConnected,
      connectedAccount, setConnectedAccount
   } = useWeb3Context();

   const firebaseConfig = {
      apiKey: "AIzaSyBXYHyZBpJwC57P0jt-_H1qpqlbjr41nHc",
      authDomain: "onelauncher-83fd7.firebaseapp.com",
      projectId: "onelauncher-83fd7",
      storageBucket: "onelauncher-83fd7.appspot.com",
      messagingSenderId: "1036407858140",
      appId: "1:1036407858140:web:40af64d011529a1157e519"
   };
   const [firebaseApp, setFirebaseApp] = useState(null);

   const initWeb3Modal = () => {
      const web3Modal = new Web3Modal({
         cacheProvider: true,
         disableInjectedProvider: false,
         providerOptions: {
            walletconnect: {
               package: WalletConnectProvider,
               options: {
                  infuraId: config.infuraId,
                  qrcodeModalOptions: {
                     mobileLinks: ["rainbow", "metamask", "argent", "trust", "imtoken", "pillar"]
                  },
               }
            }
         }
      });

      setWeb3Modal(web3Modal);
   }

   const handleTwitterLogin = async () => {
      try {
         const auth = getAuth();
         const provider = new TwitterAuthProvider();
         signInWithPopup(auth, provider)
            .then((result) => {
               const credential = TwitterAuthProvider.credentialFromResult(result);
               const token = credential.accessToken;
               const secret = credential.secret;
               const user = result.user;
               const data = JSON.parse(result._tokenResponse.rawUserInfo);
               const account = {
                  token: token,
                  secret: secret,
                  screenName: data.screen_name,
                  id: data.id_str,
                  createdAt: data.created_at,
                  followerCount: data.followers_count
               };
               setTwitterAccount(account);
            }).catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log(`Twitter login error: ${errorMessage}`);
            });
      } catch (err) {
         console.log(`Error logging-in with Twitter: ${err}`);
      }
   }

   const handleTwitterLogout = async () => {
      const auth = getAuth();
      signOut(auth).then(() => {
         setTwitterAccount(null);
       }).catch((error) => {
         setTwitterAccount(null);
       });
   }

   const handleWeb3Login = async () => {
      try {
         let provider = await web3Modal.connect();
         let web3 = new Web3(provider);

         setIsConnected(true);
         setWeb3Provider(web3Provider);
         setWeb3(web3);

         let currChainId = await web3.eth.getChainId();
         if (currChainId.toString() !== config.chainId.toString()) {
            alert(`You're on the wrong network. Please switch to ${config.chainName} and try again.`);
            return;
         }

         const accounts = await web3.eth.getAccounts();
         if (accounts.length === 0)
            return;

         let address = Web3.utils.toChecksumAddress(accounts[0]);
         setConnectedAccount(address);

         onWeb3LoggedIn(address);

         provider.on("error", e => console.error("WS Error", e));
         provider.on("end", e => console.error("WS End", e));
         provider.on("disconnect", error => {
            console.log(`Disconnect: ${error}`);
         });
         provider.on("connect", info => {
            console.log(`Connect: ${info}`);
         });
         provider.on("accountsChanged", accounts => {
            console.log(`Accounts changed: ${accounts}`);
         });
         provider.on("chainChanged", chainId => {
            console.log(`Chain changed: ${chainId}`);
         });
         // provider.on("networkChanged", networkId => {
         //    console.log(`Network changed: ${networkId}`);
         // });
      } catch (err) {
         console.log(`ERR: ${err.message}`);
      }
   }

   const handleWeb3Logout = async () => {
      try {
         if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
         }
         await web3Modal.clearCachedProvider();
         localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");

         let address = connectedAccount;

         setWeb3(null);
         setWeb3Provider(null);
         setIsConnected(false);
         setConnectedAccount(null);

         onWeb3LoggedOut(address);
      } catch (err) {
         console.log(`ERR: ${err.message}`);
      }
   }

   const handleRegister = async () => {
      alert("TODO: re-validate everything on the backend, and save the data in a DB...");
   }

   const initialize = async () => {
      setIsInitializing(true);

      try {            
         const respRules = await axios.get(`/api/getRegistrationRules?pid=${config.projectId}&env=${config.env}`);
         setRegisterRules({
            ...respRules.data,
         });
      } catch(err) {
         console.log(`ERR loading data: ${err.message}`);
         setMessage({text: `Error fetching: ${err.message}`, type: "error"});
         setIsInitializing(false);
      }      
   }

   const fetchAccountDetails = async () => {      
      console.log(`Loading User image for ${account.address}`);
      var userImageUrl = "";
      try {
         const respUser = await axios.get(`/api/getUser?address=${account.address}&env=${config.env}`);
         setAccount((prevState) => ({
            ...prevState,
            imageUrl: respUser.data.imageUrl
         }));
      } catch (err) {
         console.log(`ERR loading user: ${err.message}`);
      }
   }

   const onWeb3LoggedIn = (address) => {
      console.log(`${address} logged-in`);
      setAccount({
         address: address,
         imageUrl: null
      })
      //fetchAccountDetails();      
   }

   const onWeb3LoggedOut = (address) => {
      console.log(`${address} logged-out`);
      setAccount({
         address: null,
         imageUrl: null
      })
   }

   const getBalanceOfNFTs = async (contractAddress, accountAddress) => {
      try {
         let contract = await new web3.eth.Contract([                              
            {
               "inputs": [
                  {
                     "internalType": "address",
                     "name": "owner",
                     "type": "address"
                  }
               ],
               "name": "balanceOf",
               "outputs": [
                  {
                     "internalType": "uint256",
                     "name": "",
                     "type": "uint256"
                  }
               ],
               "stateMutability": "view",
               "type": "function"
            }], contractAddress);
      
         return await contract.methods.balanceOf(accountAddress).call();
      } catch (err) {
         return 0;
      }      
   }

   const validateWalletEthRules = async () => {      
      if (registerRules.ruleGroups.some(g => g.type === "walletEth")) {
         let validations = {...registerRuleValidations};

         let rules = registerRules.ruleGroups.filter(g => g.type === "walletEth")[0].rules;
         for (const rule of rules) {
            console.log(rule.type);
            // check ETH balance
            if (rule.type === "walletEth_minBalance") {
               if (isConnected) {
                  let balanceWei = await web3.eth.getBalance(account.address);
                  let balanceEth = web3.utils.fromWei(balanceWei)
                  console.log(`Balance: ${balanceEth} ETH`);
                  
                  if (parseFloat(balanceEth) >= parseFloat(rule.value)) {                     
                     validations[rule.type] = {
                        passed: true,
                        message: resources["walletEth_minBalance_pass"].replace("#value", parseFloat(balanceEth).toFixed(2))
                     }         
                  } else {
                     validations[rule.type] = {
                        passed: false,
                        message: resources["walletEth_minBalance_notpass"].replace("#value", parseFloat(balanceEth).toFixed(2))
                     }
                  }                                    
               } else {
                  validations[rule.type] = {
                     passed: false,
                     message: ""
                  }
               }               
            } else if (rule.type === "walletEth_mustOwnNFTs") {
               // check NFT ownership
               if (isConnected) {
                  let balanceNFTs = await getBalanceOfNFTs(rule.value, account.address);
                  console.log(`Balance of NFTs: ${balanceNFTs}`);
                  
                  if (parseInt(balanceNFTs) >= parseInt(rule.params.minQuantity)) {
                     validations[`${rule.type}_${rule.value}`] = {
                        passed: true,
                        message: resources[`walletEth_mustOwnNFTs_${rule.value}_pass`].replace("#value", balanceNFTs)
                     }
                  } else {
                     validations[`${rule.type}_${rule.value}`] = {
                        passed: false,
                        message: resources[`walletEth_mustOwnNFTs_${rule.value}_notpass`].replace("#value", balanceNFTs)
                     }
                  }                                    
               } else {
                  validations[`${rule.type}_${rule.value}`] = {
                     passed: false,
                     message: ""
                  }
               }               
            }
         }
         setRegisterRuleValidations(validations);
      }            
   }

   const validateTwitterRules = async () => {      
      if (registerRules.ruleGroups.some(g => g.type === "twitter")) {
         let validations = {...registerRuleValidations};

         let rules = registerRules.ruleGroups.filter(g => g.type === "twitter")[0].rules;
         for (const rule of rules) {
            console.log(rule.type);
            // check min followers
            if (rule.type === "twitter_minFollowerCount") {
               if (twitterAccount) {
                  if (parseInt(twitterAccount.followerCount) >= parseInt(rule.value)) {                     
                     validations[rule.type] = {
                        passed: true,
                        message: resources["twitter_minFollowerCount_pass"].replace("#value", twitterAccount.followerCount)
                     }         
                  } else {
                     validations[rule.type] = {
                        passed: false,
                        message: resources["twitter_minFollowerCount_notpass"].replace("#value", twitterAccount.followerCount)
                     }
                  }                                    
               } else {
                  validations[rule.type] = {
                     passed: false,
                     message: ""
                  }
               }               
            } else if (rule.type === "twitter_minAccountAge") {
               // check account age in days
               if (twitterAccount) {
                  const ageInDays = dayjs().diff(dayjs(twitterAccount.createdAt), "day");
                  if (ageInDays >= parseInt(rule.value)) {                     
                     validations[rule.type] = {
                        passed: true,
                        message: resources["twitter_minAccountAge_pass"].replace("#value", ageInDays)
                     }         
                  } else {
                     validations[rule.type] = {
                        passed: false,
                        message: resources["twitter_minAccountAge_notpass"].replace("#value", ageInDays)
                     }
                  }                                    
               } else {
                  validations[rule.type] = {
                     passed: false,
                     message: ""
                  }
               }          
            } else if (rule.type === "twitter_mustFollowAccount") {
               // check if follows account
               if (twitterAccount) {
                  const respRel = await axios.get(`/api/getTwitterAccountsRel?source=${twitterAccount.screenName}&target=${rule.value}`);
                  const following = respRel.data.following;
                  if (following) {
                     validations[`${rule.type}_${rule.value}`] = {
                        passed: true,
                        message: resources[`${rule.type}_${rule.value}_pass`].replace("#value", rule.value)
                     }         
                  } else {
                     validations[`${rule.type}_${rule.value}`] = {
                        passed: false,
                        message: resources[`${rule.type}_${rule.value}_notpass`].replace("#value", rule.value)
                     }
                  }                                    
               } else {
                  validations[`${rule.type}_${rule.value}`] = {
                     passed: false,
                     message: ""
                  }
               }          
            }
         }
         setRegisterRuleValidations(validations);
      }            
   }

   const refreshIsValid = async () => {
      if (!registerRules) {
         setIsValid(false);
         return;
      }

      for (const group of registerRules.ruleGroups) {
         for (const rule of group.rules) {
            let key = rule.type;
            if (rule.type === "walletEth_mustOwnNFTs" || rule.type === "twitter_mustFollowAccount")
               key = `${rule.type}_${rule.value}`;
            if (!registerRuleValidations[key] || !registerRuleValidations[key].passed) {
               setIsValid(false);
               return;
            }
         }
      }
      setIsValid(true);
   }
      
   // initial load
   useEffect(() => {
      setResources(config.resources.en);
      const app = initializeApp(firebaseConfig);
      setFirebaseApp(app);
      initWeb3Modal();
      initialize();
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // load account details
   useEffect(() => {
      //fetchAccountDetails();
      if (registerRules) {
         validateWalletEthRules();
      }
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [account.address]);

   // validate Twitter rules upon logging-in/out
   useEffect(() => {
      if (registerRules) {
         validateTwitterRules();
      }
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [twitterAccount]);

   // after loading the rules, validate them if the web3 address is already loaded
   useEffect(() => {
      if (account.address) {
         validateWalletEthRules();
      }
      if (twitterAccount) {
         validateTwitterRules();
      }
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [registerRules]);

   // try auto-login if cached
   useEffect(() => {
      refreshIsValid();
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [registerRuleValidations])

   // try auto-login if cached
   useEffect(() => {
      if (web3Modal && web3Modal.cachedProvider) {
         handleWeb3Login();
      }
      return () => {
         
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [web3Modal])

   if (registerRules) {
      return (      
         <div className="border border-gray-800 w-full">
            <div className="bg-gray-100 p-4">
               <p className="text-lg font-bold">{resources.title}</p>
               <p className="text-xs text-gray-500">{resources.description}</p>
            </div>
            <div className="p-4">               
               {registerRules != null && resources != null && registerRules.ruleGroups.map((ruleGroup, i) => { return <div key={i}>
                  <div className="grid grid-cols-2">                  
                     <div>
                        <p className="text-sm pb-2 text-gray-500 font-bold">{resources[ruleGroup.type + "_title"]}</p>
                     </div>
                     <div className="text-right">
                        {ruleGroup.type === "walletEth" && <>
                           {isConnected && connectedAccount != null ?
                              <button className="p-2 bg-gray-800 text-white text-sm rounded-md" onClick={handleWeb3Logout}>{resources["walletEth_logout"]}</button>
                           :
                              <button className="p-2 bg-gray-800 text-white text-sm rounded-md" onClick={handleWeb3Login}>{resources["walletEth_login"]}</button>
                           }
                        </>}
                        {ruleGroup.type === "twitter" && <>
                           {twitterAccount != null ?
                              <button className="p-2 bg-sky-500 text-white text-sm rounded-md" onClick={handleTwitterLogout}>{resources["twitter_logout"]}</button>
                           :                           
                              <button className="p-2 bg-sky-500 text-white text-sm rounded-md" onClick={handleTwitterLogin}>{resources["twitter_login"]}</button>
                           }
                        </>}
                     </div>
                  </div>
                  {ruleGroup.rules.map((rule, j) => { return <div key={j}>
                     {rule.type === "walletEth_minBalance" ?                  
                        <>
                        <div>
                           {registerRuleValidations.walletEth_minBalance && registerRuleValidations.walletEth_minBalance.passed ?
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-green-600"/>
                              :
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-gray-200"/>
                           }                           
                           <span className="text-sm pl-2">
                              {resources["walletEth_minBalance"].replace("#value", rule.value)}
                           </span>
                        </div>
                        {registerRuleValidations.walletEth_minBalance && registerRuleValidations.walletEth_minBalance.passed && registerRuleValidations.walletEth_minBalance.message.length > 0 &&
                           <div className="pl-8 text-green-600 text-xs">{registerRuleValidations.walletEth_minBalance.message}</div>
                        }
                        {registerRuleValidations.walletEth_minBalance && !registerRuleValidations.walletEth_minBalance.passed && registerRuleValidations.walletEth_minBalance.message.length > 0 &&
                           <div className="pl-8 text-red-600 text-xs">{registerRuleValidations.walletEth_minBalance.message}</div>
                        }
                        </>
                        : <></>
                     }
                     {rule.type === "walletEth_mustOwnNFTs" ?
                        <>
                        <div>
                           {registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value] && registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].passed ?
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-green-600"/>
                              :
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-gray-200"/>
                           }                           
                           <span className="text-sm pl-2">
                              {resources["walletEth_mustOwnNFTs_" + rule.value].replace("#value", rule.params.minQuantity)}
                           </span>
                        </div>
                        {registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value] && registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].passed && registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].message.length > 0 &&
                           <div className="pl-8 text-green-600 text-xs">{registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].message}</div>
                        }
                        {registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value] && !registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].passed && registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].message.length > 0 &&
                           <div className="pl-8 text-red-600 text-xs">{registerRuleValidations["walletEth_mustOwnNFTs_" + rule.value].message}</div>
                        }                        
                        </>
                        : <></>
                     }
                     {rule.type === "twitter_mustFollowAccount" ?
                        <>
                        <div>
                           {registerRuleValidations["twitter_mustFollowAccount_" + rule.value] && registerRuleValidations["twitter_mustFollowAccount_" + rule.value].passed ?
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-green-600"/>
                              :
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-gray-200"/>
                           }      
                           <span className="text-sm pl-2" dangerouslySetInnerHTML={{
                              __html:resources[`twitter_mustFollowAccount_${rule.value}`].replace("#value", `<a href="https://twitter.com/${rule.value}" target="_blank">@${rule.value}</a>`)
                           }} />
                        </div>
                        {registerRuleValidations["twitter_mustFollowAccount_" + rule.value] && registerRuleValidations["twitter_mustFollowAccount_" + rule.value].passed && registerRuleValidations["twitter_mustFollowAccount_" + rule.value].message.length > 0 &&
                           <div className="pl-8 text-green-600 text-xs">{registerRuleValidations["twitter_mustFollowAccount_" + rule.value].message}</div>
                        }
                        {registerRuleValidations["twitter_mustFollowAccount_" + rule.value] && !registerRuleValidations["twitter_mustFollowAccount_" + rule.value].passed && registerRuleValidations["twitter_mustFollowAccount_" + rule.value].message.length > 0 &&
                           <div className="pl-8 text-red-600 text-xs">{registerRuleValidations["twitter_mustFollowAccount_" + rule.value].message}</div>
                        }
                        </>
                        : <></>
                     }
                     {rule.type === "twitter_minFollowerCount" ?
                        <>
                        <div>
                           {registerRuleValidations["twitter_minFollowerCount"] && registerRuleValidations["twitter_minFollowerCount"].passed ?
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-green-600"/>
                              :
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-gray-200"/>
                           }      
                           <span className="text-sm pl-2">
                              {resources["twitter_minFollowerCount"].replace("#value", rule.value)}
                           </span>
                        </div>
                        {registerRuleValidations["twitter_minFollowerCount"] && registerRuleValidations["twitter_minFollowerCount"].passed && registerRuleValidations["twitter_minFollowerCount"].message.length > 0 &&
                           <div className="pl-8 text-green-600 text-xs">{registerRuleValidations["twitter_minFollowerCount"].message}</div>
                        }
                        {registerRuleValidations["twitter_minFollowerCount"] && !registerRuleValidations["twitter_minFollowerCount"].passed && registerRuleValidations["twitter_minFollowerCount"].message.length > 0 &&
                           <div className="pl-8 text-red-600 text-xs">{registerRuleValidations["twitter_minFollowerCount"].message}</div>
                        }
                        </>
                        : <></>
                     }
                     {rule.type === "twitter_minAccountAge" ?
                        <>
                        <div>
                           {registerRuleValidations["twitter_minAccountAge"] && registerRuleValidations["twitter_minAccountAge"].passed ?
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-green-600"/>
                              :
                              <CheckCircleIcon className="h-5 w-5 inline-flex stroke-gray-200"/>
                           }      
                           <span className="text-sm pl-2">
                              {resources["twitter_minAccountAge"].replace("#value", rule.value)}
                           </span>
                        </div>
                        {registerRuleValidations["twitter_minAccountAge"] && registerRuleValidations["twitter_minAccountAge"].passed && registerRuleValidations["twitter_minAccountAge"].message.length > 0 &&
                           <div className="pl-8 text-green-600 text-xs">{registerRuleValidations["twitter_minAccountAge"].message}</div>
                        }
                        {registerRuleValidations["twitter_minAccountAge"] && !registerRuleValidations["twitter_minAccountAge"].passed && registerRuleValidations["twitter_minAccountAge"].message.length > 0 &&
                           <div className="pl-8 text-red-600 text-xs">{registerRuleValidations["twitter_minAccountAge"].message}</div>
                        }
                        </>
                        : <></>
                     }
                  </div>})}
                  {i < (registerRules.ruleGroups.length - 1) &&
                     <hr className="mt-4 mb-4" />               
                  }                 
               </div>})}
            </div>
            <div className="text-right bg-gray-100 p-4">
               <button disabled={!isValid} className="p-2 bg-purple-800 disabled:opacity-30 text-white text-sm rounded-md" onClick={handleRegister}>{resources.register}</button>
            </div>
         </div>
      );
   } else if (isInitializing) {
      return (      
         <div className="border border-gray-800 p-4 w-full">         
            {resources.initializing}
         </div>
      );
   } else {
      return <></>
   }
}

export default Register;
