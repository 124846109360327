import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Web3 from "web3";
import axios from "axios";
import { ArrowCircleRightIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useWeb3Context } from "../components/hooks/Web3Context";
import { formatNumber } from "../components/utilities/MathUtils";
import Web3Login from "../components/Web3Login";
import Footer from "../components/Footer";
import NftGrid from "../components/NftGrid";
import twitter from "../images/twitter.png";
import discord from "../images/discord.png";

function Home({config}) {
   const navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams(); 
   const [owner, setOwner] = useState({
      name: searchParams.get("owner") ?? "",
      displayName: "",
      address: "",
      imageUrl: "",
      totalWorthEth: 0,
      totalWorthUsd: 0,
      totalSpentUsd: 0,
      totalSpentEth: 0,
      totalProfitUsd: 0,
      totalProfitEth: 0,
      totalProfitPerc: 0
   });
   const [isLoading, setIsLoading] = useState(false);
   const [floorPrices, setFloorPrices] = useState({isLoading: false, items: []});
   const [transfers, setTransfers] = useState({isLoading: false, items: []});
   const [financials, setFinancials] = useState({nfts: [], collections: []});
   const [ethUsdRate, setEthUsdRate] = useState(0);
   const [inputAccount, setInputAccount] = useState("");
   const [nfts, setNfts] = useState([]);   
   const [message, setMessage] = useState({text: "", type: "info"});
   const web3Context = useWeb3Context();

   const fetchData = async (rawAddress) => {
      setMessage({
         text: "Loading NFTs...",
         type: "info"
      });
      setIsLoading(true);

      try {
         // resolving ens domain, if one is used
         var address = "";
         if (rawAddress.toLowerCase().endsWith(".eth")) {
            try {
               const respEns = await axios.get(`/api/getEnsAddress?domain=${rawAddress}&env=${config.env}`);
               address = respEns.data.address;
            } catch (err) {
               address = rawAddress;
            }
         } else {
            address = rawAddress;
         }         

         setOwner((prevState) => ({
            ...prevState,
            name: rawAddress,
            displayName: rawAddress.toLowerCase().endsWith(".eth") ? 
               rawAddress :
               String(address).substring(0, 6) + "..." + String(address).substring(30),
            address: address,
            imageUrl: "", 
            totalWorthEth: 0, 
            totalWorthUsd: 0,
            totalSpentEth: 0, 
            totalSpentUsd: 0,
            totalProfitEth: 0, 
            totalProfitUsd: 0,
            totalProfitPerc: 0
         }));         
         
         // loading NFTs
         let tempNfts = [];
         let pageKey = "";
         do {
            console.log(`Loading NFTs for ${address} and page ${pageKey}`);         
            const respNfts = await axios.get(`/api/getOwnerNfts?owner=${address}&pageKey=${pageKey}&env=${config.env}`);
            tempNfts = tempNfts.concat(respNfts.data.items);
            pageKey = respNfts.data.pageKey;
         } while (pageKey != null && pageKey.length > 0);         

         // fetch the transfers
         const transfers = await fetchTransfers(address, tempNfts);

         // associate a transferDate to NFTs, if any
         tempNfts = tempNfts.map((nft, i) => {
            const tr = transfers.filter(t => t.contractAddress === nft.contractAddress && t.tokenId === nft.tokenId);
            return {
               ...nft,
               transferDate: tr != null && tr.length > 0 && tr[0].blockDate != null && tr[0].blockDate.length > 0 ? 
                  Date.parse(tr[0].blockDate) : Date.parse("1900-01-01")
            }
         });         

         // add NFTS with media and collection at the top of the destination array
         let orderedNfts = [];
         let nftsWithMediaAndCollection = tempNfts.filter(n => (n.mediaUrl != null && n.mediaUrl.length > 0 && n.collection != null) || n.isEnsDomain);
         nftsWithMediaAndCollection.sort((a, b) => {
            return b.transferDate - a.transferDate;
         });
         orderedNfts = orderedNfts.concat(nftsWithMediaAndCollection);
         // then add those with media but no collection
         let nftsWithMedia = tempNfts.filter(n => !orderedNfts.some(x => x === n))
            .filter(n => n.mediaUrl != null && n.mediaUrl.length > 0);
         nftsWithMedia.sort((a, b) => {
            return b.transferDate - a.transferDate;
         });
         orderedNfts = orderedNfts.concat(nftsWithMedia);         
         // add remaining tokens
         orderedNfts = orderedNfts.concat(tempNfts.filter(n => 
            !orderedNfts.some(x => x === n) ));

         // can now show the tokens and progress with the rest
         setNfts(orderedNfts);
         setMessage({text: "", type: "info"});
         setIsLoading(false);

         // loading the user
         console.log(`Loading User image for ${address}`);
         var userImageUrl = "";
         try {
            const respUser = await axios.get(`/api/getUser?address=${address}&env=${config.env}`);
            setOwner((prevState) => ({
               ...prevState,
               imageUrl: respUser.data.imageUrl
            }));
         } catch (err) {
            console.log(`ERR loading user: ${err.message}`);
         }     
      } catch(err) {
         console.log(`ERR loading data: ${err.message}`);
         setNfts([]);
         setMessage({text: `Error fetching: ${err.message}`, type: "error"});
         setIsLoading(false);
      }      
   }

   const fetchFloorPrices = async () => {
      const filteredNfts = nfts.filter(nft => nft.collection != null);
      if (filteredNfts.length === 0)
         return;

      let prices = {...floorPrices};
      prices.isLoading = true;
      setFloorPrices({
         ...prices,
         isLoading: false
      });

      for (const nft of filteredNfts) {
         try {
            // skip this if already retrieved from a previous nft
            if (prices.items[nft.collection.slug] != null)
               continue;

            const respFloor = await axios.get(`https://api.opensea.io/api/v1/collection/${nft.collection.slug}/stats`);            
            console.log(`${nft.collection.slug} => ${respFloor.data.stats.floor_price}`);
            prices.items[nft.collection.slug] = respFloor.data.stats.floor_price;  
            setFloorPrices({...prices});
         } catch (err) {
            console.log(`ERR loading OpenSea data: ${err.message}`);
         }
      }
      
      setFloorPrices({
         ...prices,
         isLoading: false
      });
      console.log(prices);
   }

   const fetchTransfers = async (address, nfts) => {
      setTransfers({items: [], isLoading: true});
      
      try {
         const respTransfers = await axios.get(`/api/getNftTransfers?owner=${address}`);            
         let allTrans = respTransfers.data.items;
         // filter out all transfers that are relative to NFTs no longer owned
         allTrans = allTrans.filter(t => 
            nfts.some(n => n.contractAddress === t.contractAddress && n.tokenId === t.tokenId)
         );
         // if a ERC721 token is purchased, sold and purchased again, there will be multiple transfers
         // in the array. Do a deduplication by only taking the first transfer, which is the most recent one.
         // This is only for ERC721, because ERC1155 or fungible tokens can have transfers for 
         // the same contract/id just to increase the quantity
         let trans = [];
         allTrans.forEach(t => {
            if (!trans.some(x => x.tokenType === "ERC721" && x.contractAddress === t.contractAddress && x.tokenId === t.tokenId)) {
               trans.push(t);
            }
         });
         console.log(`Transfers: ${trans}`);
         // get all NFTs without a matching purchase transaction
         const nftsWithoutTransfer = nfts.filter(n => !trans.some(t => t.contractAddress === n.contractAddress && t.tokenId === n.tokenId));
         console.log(`NFTs without transfer: ${nftsWithoutTransfer}`);

         setTransfers({items: trans, isLoading: false});
         return trans;
      } catch (err) {
         console.log(`ERR loading transfers: ${err.message}`);
         setTransfers({items: [], isLoading: false});
         return [];
      }      
   }

   const fetchEthUsdRate = async () => {
      try {
         const respEthUsd = await axios.get("https://api.coinbase.com/v2/prices/ETH-USD/sell");
         const rate = parseFloat(respEthUsd.data.data.amount.replace(",", "."));
         setEthUsdRate(rate);         
      } catch (err) {
         console.log(`ERR loading ETH rate: ${err.message}`);
      }
   }

   const calcAllFinancials = () => {
      let nftFinancials = [];
      let colFinancials = [];

      nfts.filter(n => n.collection != null).forEach(n => {
         const f = calcNftFinancials(n);
         if (f == null)
            return;
            
         nftFinancials[`${n.collection.slug}-${n.tokenId}`] = f;

         if (colFinancials[n.collection.slug] == null) {
            colFinancials[n.collection.slug] = f;
         } else {
            const fprev = colFinancials[n.collection.slug];
            colFinancials[n.collection.slug] = {
               worth: fprev.worth + f.worth,
               spent: fprev.spent + f.spent,
               profit: fprev.profit + f.profit
            }
         }
      });

      const fin = {nfts: nftFinancials, collections: colFinancials};
      setFinancials(fin);
      return fin;
   }

   const calcNftFinancials = (nft) => {
      const trans = transfers.items.filter(t => t.contractAddress === nft.contractAddress && t.tokenId === nft.tokenId);
      // a transfer should be present in the trans array, but if the API failed for whatever reason or
      // returned incomplete data, return null at this point
      if (trans.length === 0)
         return null;

      // get the floor price for this nft, but consider it could be null if not retrieved yet
      const floorPrice = nft.collection != null && floorPrices.items[nft.collection.slug] != null ?
         floorPrices.items[nft.collection.slug] : 0;

      let res = {};
      if (nft.tokenCount === 1) {
         res.spent = trans[0].value;
      } else {
         const totalTokenCount = trans.reduce((partialSum, t) => partialSum + t.tokenCount, 0);
         const totalTokenValueSpent = trans.reduce((partialSum, t) => partialSum + t.value, 0);
         // if the token count from the transfers matches the current count in the wallet,
         // it's easy, just consider those transfer's total expense...
         if (totalTokenCount === nft.tokenCount) {
            res.spent = totalTokenValueSpent;
         } else {
            // ...otherwise calc the individual unit's expense and calculate the current total
            res.spent = Math.round(totalTokenValueSpent / totalTokenCount * nft.tokenCount);
         }
      }
      res.worth = floorPrice * nft.tokenCount;
      res.profit = res.worth - res.spent;
      return res;
   }

   const handleLoadAccount = () => {
      if (inputAccount.trim().length > 0) {
         loadAccount(inputAccount.trim());
      }      
   }

   const loadAccount = (rawAddress) => {
      navigate(`?owner=${rawAddress}`);
      fetchData(rawAddress);
   }   

   // if the page is not already showing the content of another address when
   // the user logs in, load the logged-in wallet
   const onLoggedIn = (address) => {
      if (owner.name !== "")
         return;
      
      console.log(`Loading account: ${address}`);
      setInputAccount(address);
      loadAccount(address);
   }

   // if the page is not already showing the content of another address when
   // the user logs out, clear everything
   const onLoggedOut = (address) => {
      console.log(`Current owner's address: ${owner.address.toLocaleLowerCase()} - Logged-out address: ${address.toLowerCase()}`);
      if (address.toLowerCase() !== owner.address.toLocaleLowerCase())
         return;

      navigate("/");
      setInputAccount("");
      setNfts([]);
      setFloorPrices({isLoading: false, items: []});
      setTransfers({isLoading: false, items: []});
      setMessage({text: "", type: "info"});
      setIsLoading(false);      
      setOwner({
         name: "",
         displayName: "",
         address: "",
         imageUrl: "",
         totalWorthEth: 0,
         totalWorthUsd: 0,
         totalSpentUsd: 0,
         totalSpentEth: 0,
         totalProfitUsd: 0,
         totalProfitEth: 0,
         totalProfitPerc: 0
      });
   }

   // calculate the user wallet's worth and the other financials
   useEffect(() => {
      // calculate the worth
      const nftsWithFloorPrice = nfts.filter(nft => nft.collection != null && 
         floorPrices.items[nft.collection.slug] != null);
      const worthEth = nftsWithFloorPrice.reduce((partialSum, nft) => 
            partialSum + (floorPrices.items[nft.collection.slug] * nft.tokenCount), 0);
      const worthUsd = worthEth * ethUsdRate;

      console.log(`USD value: ${worthUsd} | ETH value: ${worthEth}`)

      // calculate the NFT financials, and roll up things for the entire wallet
      let worthEth2 = 0;
      let worthUsd2 = 0;
      let spentEth = 0;
      let spentUsd = 0;
      let profitEth = 0;
      let profitUsd = 0;
      let profitPerc = 0;
      if (!transfers.isLoading && transfers.items.length > 0) {
         const fin = calcAllFinancials();
         worthEth2 = Object.values(fin.collections).reduce((partialSum, f) => partialSum + f.worth, 0);
         worthUsd2 = worthEth2 * ethUsdRate;
         spentEth = Object.values(fin.collections).reduce((partialSum, f) => partialSum + f.spent, 0);
         spentUsd = spentEth * ethUsdRate;         
         profitEth = worthEth - spentEth;
         profitUsd = profitEth * ethUsdRate;
         profitPerc = profitEth / spentEth * 100;
      }      

      setOwner((prevState) => ({
         ...prevState,
         totalWorthUsd: worthUsd,
         totalWorthEth: worthEth,
         totalWorthUsd2: worthUsd2,
         totalWorthEth2: worthEth2,
         totalSpentUsd: spentUsd,
         totalSpentEth: spentEth,
         totalProfitUsd: profitUsd,
         totalProfitEth: profitEth,
         totalProfitPerc: profitPerc
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [floorPrices, transfers, ethUsdRate]);

   // refresh floor prices and usd-eth rate upon getting new NFTs
   useEffect(() => {
      if (nfts.length > 0) {
         fetchFloorPrices();         
         fetchEthUsdRate();
      }      
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [nfts]);

   // initial load, if the account is present on the querystring
   useEffect(() => {
      if (owner.name.length > 0){
         setInputAccount(owner.name);
         fetchData(owner.name);
      }      
      return () => {
         setNfts([]); 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="h-screen dark:bg-zinc-900">
         <div className="p-2 text-center text-sm font-medium bg-yellow-400 text-black">
            <a className="no-underline" href="https://github.com/NinjaDevHS" target="_blank" rel="noreferrer">
            Dev wanting to create your own NFT collection? Need tools and a minting website?{" "}
            <span className="underline">We've got all that on GitHub!</span></a>
         </div>
         <div className="bg-indigo-800 dark:bg-zinc-900 header-pattern">
            <div className="max-w-screen-lg mx-auto px-4 py-12">
               <div className="flex flex-col-reverse md:flex-row gap-16">
                  <div className="flex-2 flex flex-col gap-8">
                     <h1 className="text-4xl font-medium text-cyan-300">OneLauncher Web3 Tools</h1> 
                  </div>                  
                  <div className="flex-1 grid">
                     <div className="p-2 text-right">
                        <Web3Login infuraId={config.infura_id} chainId={config.chain_id} chainName={config.chain_name} onLoggedIn={onLoggedIn} onLoggedOut={onLoggedOut} />
                     </div>
                  </div>
               </div>               
            </div>            
         </div>           

         <div className="">
            {/* Address input */}
            <div className="bg-white dark:bg-zinc-800">         
               <div className="max-w-screen-sm mx-auto px-4 py-8 flex flex-row gap-2">               
                  <input placeholder=".eth name or 0x... address" className="w-full p-2 h-10 border border-gray-800 dark:border-black/25 rounded-md dark:bg-zinc-900/25 dark:text-neutral-50" value={inputAccount} onChange={e => setInputAccount(e.target.value.trim())} onKeyUp={(e) => { if (e.keyCode===13) {handleLoadAccount();} }}></input>
                  <button className="self-start p-2 px-4 bg-gray-200 hover:bg-gray-300 dark:text-white dark:bg-violet-900 dark:hover:bg-violet-500/75 rounded-md h-10" onClick={handleLoadAccount}><ArrowRightIcon className="h-5 w-5 inline-flex dark:stroke-white"/></button>
               </div>            
            </div>

            {/* Message box */}
            {message.text.length > 0 &&
            <div className="bg-white dark:bg-zinc-800">         
               <div className="max-w-screen-2xl mx-auto px-4 py-8 flex flex-col gap-8">
                  <div className="p-4 bg-gray-50 dark:bg-zinc-800 dark:text-neutral-50 text-center">{message.text}</div>
               </div>
            </div>
            }

            {/* NFTs in wallet */}            
            <div className="bg-white dark:bg-zinc-800">         
               <div className="max-w-screen-2xl mx-auto px-4 py-8 flex flex-col gap-8 min-h-[50%]">
                  {owner.displayName.length > 0 &&
                     <>
                     <div className="flex flex-col gap-2">
                        <h1 className="text-3xl font-medium text-center dark:text-neutral-50">
                           {owner.displayName}'s NFTs                  
                        </h1>
                        {floorPrices.isLoading &&
                           <div className="text-center text-xl font-medium dark:text-neutral-50">Calculating value...</div>
                        }
                        {owner.totalSpentEth > 0 &&
                           <div className="grid grid-cols-2 text-xl font-medium">
                              <div className="dark:text-neutral-50 text-right"><small className="dark:text-neutral-50">Spent:</small> Ξ{owner.totalSpentEth.toFixed(2)}&nbsp;</div>
                              {owner.totalSpentUsd > 0 &&
                                 <div className="dark:text-neutral-50">{" | "}${formatNumber(owner.totalSpentUsd.toFixed(0))}</div>
                              }
                           </div>
                        }
                        {owner.totalWorthEth > 0 &&
                           <div className="grid grid-cols-2 text-xl font-medium">
                              <div className="dark:text-neutral-50 text-right"><small className="dark:text-neutral-50">Worth:</small> Ξ{owner.totalWorthEth.toFixed(2)}&nbsp;</div>
                              {owner.totalWorthUsd > 0 &&
                                 <div className="dark:text-neutral-50">{" | "}${formatNumber(owner.totalWorthUsd.toFixed(0))}</div>
                              }
                           </div>
                        }
                        {!floorPrices.isLoading && owner.totalWorthEth > 0 && owner.totalSpentEth > 0 &&
                           <div className="grid grid-cols-2 text-xl font-medium">
                              <div className={"text-right " + (owner.totalProfitEth >= 0 ? "text-green-700" : "text-red-700")}>
                                 <small className={owner.totalProfitEth >= 0 ? "text-green-700" : "text-red-700"}>P/L:</small> {owner.totalProfitEth > 0 ? "+" : "-"}Ξ{Math.abs(owner.totalProfitEth.toFixed(2))}&nbsp;</div>
                              {owner.totalWorthUsd > 0 &&
                                 <div className={owner.totalProfitEth >= 0 ? "text-green-700" : "text-red-700"}>
                                    {" | "}{owner.totalProfitEth > 0 ? "+" : "-"}${formatNumber(Math.abs(owner.totalProfitUsd.toFixed()))}{" "}
                                    <small className={owner.totalProfitEth >= 0 ? "text-green-700" : "text-red-700"}>
                                       ({owner.totalProfitPerc > 0 ? "+" : ""}{Math.round(owner.totalProfitPerc)}{"%"})
                                    </small>
                                 </div>
                              }
                           </div>
                        }
                        {owner.imageUrl.length > 0 &&
                           <div className='flex items-center justify-center'>
                              <img src={owner.imageUrl} className="w-20 rounded-full" alt="" />   
                           </div>
                        }
                     </div>
                     {!isLoading &&
                        <NftGrid nfts={nfts} floorPrices={floorPrices} financials={financials} />
                     }
                     </>
                  }
               </div>            
            </div>

         </div>

         <Footer />
      </div>
   );
}

export default Home;
